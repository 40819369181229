import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-main-inner__wrapper" }
const _hoisted_2 = { class: "cp-uppercase" }
const _hoisted_3 = { class: "cp-form__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_title_return = _resolveComponent("title-return")!
  const _component_form_block = _resolveComponent("form-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_title_return, {
      class: "cp-margin__t--small cp-margin__b--small",
      onClick: _ctx.returnBefore
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.titlePage), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_form_block, { onReturnBefore: _ctx.returnBefore }, null, 8, ["onReturnBefore"])
    ])
  ]))
}