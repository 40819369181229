
import { Component } from '@/lib/decorator';
import FormBlock from '@/views/partners/profile/_form.vue';
import Loading from '@/components/Loading.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import Form from '@/layouts/Form';
import PartnersModule from '@/store/partners';
import PartnerEntityModule from '@/store/partners/entity';

@Component({
  components: {
    FormBlock,
    Loading,
    TitleReturn,
  },
})
export default class PartnersEntityAdd extends Form {
  get titlePage(): string {
    return PartnersModule.pageSettings.titleAdd;
  }

  get isLoading(): boolean {
    return PartnerEntityModule.isLoading;
  }

  returnBefore(): void {
    this.$router.push({ name: 'partners' });
  }

  created(): void {
    PartnerEntityModule.initAdd();
  }
}
